export type TypeCity = {
    country: string;
    name: string;
    coordinate: [number, number]; /* [longitude, latitude] */
}

export const cities: TypeCity[] = [
    /* Europa */
    { coordinate: [19.8172, 41.3317], country: "AL", name: "Tirana" },
    { coordinate: [1.5218, 42.5075], country: "AD", name: "Andorra la Vella" },
    { coordinate: [16.3728, 48.2092], country: "AT", name: "Wien" },
    { coordinate: [27.5766, 53.9678], country: "BY", name: "Minsk" },
    { coordinate: [4.3676, 50.8371], country: "BE", name: "Brüssel" },
    { coordinate: [18.4214, 43.8608], country: "BA", name: "Sarajevo" },
    { coordinate: [23.3238, 42.7105], country: "BG", name: "Sofia" },
    { coordinate: [15.9785, 45.8150], country: "HR", name: "Zagreb" },
    { coordinate: [14.4205, 50.0878], country: "CZ", name: "Prag" },
    { coordinate: [12.5681, 55.6763], country: "DK", name: "Kopenhagen" },
    { coordinate: [24.7545, 59.4389], country: "EE", name: "Tallinn" },
    { coordinate: [24.9384, 60.1699], country: "FI", name: "Helsinki" },
    { coordinate: [2.3510, 48.8567], country: "FR", name: "Paris" },
    { coordinate: [13.4115, 52.5235], country: "DE", name: "Berlin" },
    { coordinate: [23.7166, 37.9792], country: "GR", name: "Athen" },
    { coordinate: [19.0408, 47.4984], country: "HU", name: "Budapest" },
    { coordinate: [-21.8952, 64.1353], country: "IS", name: "Reykjavik" },
    { coordinate: [-6.2675, 53.3441], country: "IE", name: "Dublin" },
    { coordinate: [12.4823, 41.8955], country: "IT", name: "Rom" },
    { coordinate: [21.1788, 42.6740], country: "XK", name: "Pristina" },
    { coordinate: [24.1048, 56.9465], country: "LV", name: "Riga" },
    { coordinate: [9.5215, 47.1411], country: "LI", name: "Vaduz" },
    { coordinate: [25.2799, 54.6896], country: "LT", name: "Vilnius" },
    { coordinate: [6.1296, 49.6100], country: "LU", name: "Luxemburg" },
    { coordinate: [14.5189, 35.9042], country: "MT", name: "Valletta" },
    { coordinate: [28.8497, 47.0167], country: "MD", name: "Chișinău" },
    { coordinate: [7.4189, 43.7325], country: "MC", name: "Monaco" },
    { coordinate: [19.2595, 42.4602], country: "ME", name: "Podgorica" },
    { coordinate: [4.8910, 52.3738], country: "NL", name: "Amsterdam" },
    { coordinate: [21.4361, 42.0024], country: "MK", name: "Skopje" },
    { coordinate: [10.7387, 59.9138], country: "NO", name: "Oslo" },
    { coordinate: [21.0122, 52.2297], country: "PL", name: "Warschau" },
    { coordinate: [-9.1355, 38.7072], country: "PT", name: "Lissabon" },
    { coordinate: [26.0979, 44.4479], country: "RO", name: "Bukarest" },
    { coordinate: [37.6176, 55.7558], country: "RU", name: "Moskau" },
    { coordinate: [12.4578, 43.9424], country: "SM", name: "San Marino" },
    { coordinate: [20.4781, 44.8048], country: "RS", name: "Belgrad" },
    { coordinate: [17.1547, 48.2116], country: "SK", name: "Bratislava" },
    { coordinate: [14.5060, 46.0514], country: "SI", name: "Ljubljana" },
    { coordinate: [-3.7033, 40.4167], country: "ES", name: "Madrid" },
    { coordinate: [18.0645, 59.3328], country: "SE", name: "Stockholm" },
    { coordinate: [7.4481, 46.9480], country: "CH", name: "Bern" },
    { coordinate: [30.5367, 50.4422], country: "UA", name: "Kiew" },
    { coordinate: [-0.1262, 51.5002], country: "GB", name: "London" },
    { coordinate: [-6.7719, 62.0177], country: "FO", name: "Tórshavn" },
    { coordinate: [-5.3453, 36.1377], country: "GI", name: "Gibraltar" },
    { coordinate: [-2.5522, 49.4660], country: "GG", name: "Saint Peter Port" },
    { coordinate: [-4.4821, 54.1670], country: "IM", name: "Douglas" },
    { coordinate: [-2.1071, 49.1919], country: "JE", name: "Saint Helier" },
    { coordinate: [15.6488, 78.2186], country: "SJ", name: "Longyearbyen" },

    /* Asia */
    { coordinate: [69.1952, 34.5155], country: "AF", name: "Kabul" },
    { coordinate: [44.5090, 40.1596], country: "AM", name: "Yerevan" },
    { coordinate: [49.8932, 40.3834], country: "AZ", name: "Baku" },
    { coordinate: [50.5354, 26.1921], country: "BH", name: "Manama" },
    { coordinate: [90.3978, 23.7106], country: "BD", name: "Dhaka" },
    { coordinate: [89.6730, 27.4405], country: "BT", name: "Thimphu" },
    { coordinate: [114.9425, 4.9431], country: "BN", name: "Bandar Seri Begawan" },
    { coordinate: [104.8984, 11.5434], country: "KH", name: "Phnom Penh" },
    { coordinate: [116.3958, 39.9056], country: "CN", name: "Beijing" },
    { coordinate: [33.3736, 35.1676], country: "CY", name: "Nicosia" },
    { coordinate: [44.7930, 41.7010], country: "GE", name: "Tbilisi" },
    { coordinate: [77.2250, 28.6353], country: "IN", name: "New Delhi" },
    { coordinate: [106.8063, -6.1862], country: "ID", name: "Jakarta" },
    { coordinate: [51.4358, 35.7061], country: "IR", name: "Tehran" },
    { coordinate: [44.3922, 33.3157], country: "IQ", name: "Baghdad" },
    { coordinate: [35.2007, 31.7857], country: "IL", name: "Jerusalem" },
    { coordinate: [139.6823, 35.6785], country: "JP", name: "Tokyo" },
    { coordinate: [35.9349, 31.9394], country: "JO", name: "Amman" },
    { coordinate: [71.4475, 51.1796], country: "KZ", name: "Astana" },
    { coordinate: [47.9824, 29.3721], country: "KW", name: "Kuwait City" },
    { coordinate: [74.5984, 42.8679], country: "KG", name: "Bishkek" },
    { coordinate: [102.6137, 17.9689], country: "LA", name: "Vientiane" },
    { coordinate: [35.5134, 33.8872], country: "LB", name: "Beirut" },
    { coordinate: [101.7077, 3.1502], country: "MY", name: "Kuala Lumpur" },
    { coordinate: [73.5109, 4.1742], country: "MV", name: "Male" },
    { coordinate: [106.9220, 47.9138], country: "MN", name: "Ulaanbaatar" },
    { coordinate: [96.1345, 19.7540], country: "MM", name: "Naypyidaw" },
    { coordinate: [85.3157, 27.7058], country: "NP", name: "Kathmandu" },
    { coordinate: [125.7468, 39.0187], country: "KP", name: "Pyongyang" },
    { coordinate: [58.5922, 23.6086], country: "OM", name: "Muscat" },
    { coordinate: [73.0946, 33.6751], country: "PK", name: "Islamabad" },
    { coordinate: [120.9726, 14.5790], country: "PH", name: "Manila" },
    { coordinate: [51.5082, 25.2948], country: "QA", name: "Doha" },
    { coordinate: [46.6977, 24.6748], country: "SA", name: "Riyadh" },
    { coordinate: [103.8500, 1.2894], country: "SG", name: "Singapore" },
    { coordinate: [126.9828, 37.5139], country: "KR", name: "Seoul" },
    { coordinate: [79.8572, 6.9155], country: "LK", name: "Colombo" },
    { coordinate: [36.2939, 33.5158], country: "SY", name: "Damascus" },
    { coordinate: [68.7738, 38.5737], country: "TJ", name: "Dushanbe" },
    { coordinate: [100.5020, 13.7573], country: "TH", name: "Bangkok" },
    { coordinate: [125.5880, -8.5662], country: "TL", name: "Dili" },
    { coordinate: [32.8560, 39.9439], country: "TR", name: "Ankara" },
    { coordinate: [58.3794, 37.9509], country: "TM", name: "Ashgabat" },
    { coordinate: [54.3705, 24.4764], country: "AE", name: "Abu Dhabi" },
    { coordinate: [69.2481, 41.3193], country: "UZ", name: "Tashkent" },
    { coordinate: [105.8372, 21.0341], country: "VN", name: "Hanoi" },
    { coordinate: [44.2081, 15.3556], country: "YE", name: "Sanaa" },
    { coordinate: [121.5645, 25.0338], country: "TW", name: "Taipei" },

    /* America */
    { coordinate: [-61.8456, 17.1175], country: "AG", name: "Saint John's" },
    { coordinate: [-58.4173, -34.6118], country: "AR", name: "Buenos Aires" },
    { coordinate: [-77.3390, 25.0661], country: "BS", name: "Nassau" },
    { coordinate: [-59.6105, 13.0935], country: "BB", name: "Bridgetown" },
    { coordinate: [-88.7713, 17.2534], country: "BZ", name: "Belmopan" },
    { coordinate: [-65.2559, -19.0421], country: "BO", name: "Sucre" },
    { coordinate: [-47.9292, -15.7801], country: "BR", name: "Brasilia" },
    { coordinate: [-75.6979, 45.4235], country: "CA", name: "Ottawa" },
    { coordinate: [-70.6420, -33.4691], country: "CL", name: "Santiago" },
    { coordinate: [-74.0962, 4.6473], country: "CO", name: "Bogota" },
    { coordinate: [-84.1002, 9.9402], country: "CR", name: "San Jose" },
    { coordinate: [-82.3667, 23.1333], country: "CU", name: "Havana" },
    { coordinate: [-61.3900, 15.2976], country: "DM", name: "Roseau" },
    { coordinate: [-69.8908, 18.4790], country: "DO", name: "Santo Domingo" },
    { coordinate: [-78.5243, -0.2295], country: "EC", name: "Quito" },
    { coordinate: [-89.2073, 13.7034], country: "SV", name: "San Salvador" },
    { coordinate: [-61.7486, 12.0540], country: "GD", name: "Saint George's" },
    { coordinate: [-90.5328, 14.6248], country: "GT", name: "Guatemala" },
    { coordinate: [-58.1548, 6.8046], country: "GY", name: "Georgetown" },
    { coordinate: [-72.3288, 18.5392], country: "HT", name: "Port-au-Prince" },
    { coordinate: [-87.2063, 14.0821], country: "HN", name: "Tegucigalpa" },
    { coordinate: [-76.7920, 17.9927], country: "JM", name: "Kingston" },
    { coordinate: [-99.1276, 19.4271], country: "MX", name: "Mexico City" },
    { coordinate: [-86.2734, 12.1475], country: "NI", name: "Managua" },
    { coordinate: [-79.5188, 8.9943], country: "PA", name: "Panama City" },
    { coordinate: [-57.6362, -25.3005], country: "PY", name: "Asuncion" },
    { coordinate: [-77.0465, -12.0931], country: "PE", name: "Lima" },
    { coordinate: [-62.7138, 17.2968], country: "KN", name: "Basseterre" },
    { coordinate: [-60.0018, 13.9972], country: "LC", name: "Castries" },
    { coordinate: [-61.2653, 13.2035], country: "VC", name: "Kingstown" },
    { coordinate: [-55.1679, 5.8232], country: "SR", name: "Paramaribo" },
    { coordinate: [-61.4789, 10.6596], country: "TT", name: "Port of Spain" },
    { coordinate: [-77.0241, 38.8921], country: "US", name: "Washington, D.C." },
    { coordinate: [-56.0675, -34.8941], country: "UY", name: "Montevideo" },
    { coordinate: [-66.8983, 10.4961], country: "VE", name: "Caracas" },
    { coordinate: [-63.0669, 18.2249], country: "AI", name: "The Valley" },
    { coordinate: [-70.0265, 12.5246], country: "AW", name: "Oranjestad" },
    { coordinate: [-64.7820, 32.2930], country: "BM", name: "Hamilton" },
    { coordinate: [-64.6235, 18.4328], country: "VG", name: "Road Town" },
    { coordinate: [-81.3857, 19.3022], country: "KY", name: "George Town" },
    { coordinate: [-57.8492, -51.7010], country: "FK", name: "Stanley" },
    { coordinate: [-52.3303, 4.9346], country: "GF", name: "Cayenne" },
    { coordinate: [-51.7214, 64.1836], country: "GL", name: "Nuuk" },
    { coordinate: [-61.7220, 15.9985], country: "GP", name: "Basse-Terre" },
    { coordinate: [-61.0760, 14.5997], country: "MQ", name: "Fort-de-France" },
    { coordinate: [-62.2014, 16.6802], country: "MS", name: "Plymouth" },
    { coordinate: [-68.9335, 12.1034], country: "AN", name: "Willemstad" },
    { coordinate: [-66.0667, 18.4500], country: "PR", name: "San Juan" },
    { coordinate: [-56.1968, 46.7878], country: "PM", name: "Saint-Pierre" },
    { coordinate: [-71.1363, 21.4608], country: "TC", name: "Cockburn Town" },
    { coordinate: [-64.9326, 18.3405], country: "VI", name: "Charlotte Amalie" },

    /* Africa */
    { coordinate: [3.0597, 36.7755], country: "DZ", name: "Algiers" },
    { coordinate: [13.2306, -8.8159], country: "AO", name: "Luanda" },
    { coordinate: [2.6323, 6.4779], country: "BJ", name: "Porto-Novo" },
    { coordinate: [25.9089, -24.6570], country: "BW", name: "Gaborone" },
    { coordinate: [-1.5352, 12.3569], country: "BF", name: "Ouagadougou" },
    { coordinate: [29.3622, -3.3818], country: "BI", name: "Bujumbura" },
    { coordinate: [11.5217, 3.8612], country: "CM", name: "Yaounde" },
    { coordinate: [-23.5153, 14.9195], country: "CV", name: "Praia" },
    { coordinate: [18.5873, 4.3621], country: "CF", name: "Bangui" },
    { coordinate: [15.0355, 12.1121], country: "TD", name: "N'Djamena" },
    { coordinate: [43.2412, -11.7004], country: "KM", name: "Moroni" },
    { coordinate: [15.2662, -4.2767], country: "CG", name: "Brazzaville" },
    { coordinate: [15.3271, -4.3369], country: "CD", name: "Kinshasa" },
    { coordinate: [-5.2728, 6.8067], country: "CI", name: "Yamoussoukro" },
    { coordinate: [43.1425, 11.5806], country: "DJ", name: "Djibouti" },
    { coordinate: [31.2272, 30.0571], country: "EG", name: "Cairo" },
    { coordinate: [8.7741, 3.7523], country: "GQ", name: "Malabo" },
    { coordinate: [38.9183, 15.3315], country: "ER", name: "Asmara" },
    { coordinate: [31.1410, -26.3186], country: "SZ", name: "Mbabane" },
    { coordinate: [38.7575, 9.0084], country: "ET", name: "Addis Ababa" },
    { coordinate: [9.4496, 0.3858], country: "GA", name: "Libreville" },
    { coordinate: [-16.6775, 13.4399], country: "GM", name: "Banjul" },
    { coordinate: [-0.2074, 5.5401], country: "GH", name: "Accra" },
    { coordinate: [-13.6785, 9.5370], country: "GN", name: "Conakry" },
    { coordinate: [-15.5875, 11.8598], country: "GW", name: "Bissau" },
    { coordinate: [36.7965, -1.2762], country: "KE", name: "Nairobi" },
    { coordinate: [27.4854, -29.2976], country: "LS", name: "Maseru" },
    { coordinate: [-10.8047, 6.3106], country: "LR", name: "Monrovia" },
    { coordinate: [13.1897, 32.8830], country: "LY", name: "Tripoli" },
    { coordinate: [47.5237, -18.9201], country: "MG", name: "Antananarivo" },
    { coordinate: [33.7703, -13.9899], country: "MW", name: "Lilongwe" },
    { coordinate: [-7.9864, 12.6530], country: "ML", name: "Bamako" },
    { coordinate: [-15.9900, 18.0669], country: "MR", name: "Nouakchott" },
    { coordinate: [57.4896, -20.1654], country: "MU", name: "Port Louis" },
    { coordinate: [-6.8704, 33.9905], country: "MA", name: "Rabat" },
    { coordinate: [32.5804, -25.9686], country: "MZ", name: "Maputo" },
    { coordinate: [17.0805, -22.5749], country: "NA", name: "Windhoek" },
    { coordinate: [2.1157, 13.5164], country: "NE", name: "Niamey" },
    { coordinate: [7.4891, 9.0580], country: "NG", name: "Abuja" },
    { coordinate: [30.0619, -1.9441], country: "RW", name: "Kigali" },
    { coordinate: [6.7311, 0.3360], country: "ST", name: "Sao Tome" },
    { coordinate: [-17.4439, 14.6953], country: "SN", name: "Dakar" },
    { coordinate: [55.4500, -4.6167], country: "SC", name: "Victoria" },
    { coordinate: [-13.2659, 8.4697], country: "SL", name: "Freetown" },
    { coordinate: [45.3426, 2.0411], country: "SO", name: "Mogadishu" },
    { coordinate: [28.1876, -25.7463], country: "ZA", name: "Pretoria" },
    { coordinate: [32.5322, 15.5501], country: "SD", name: "Khartoum" },
    { coordinate: [31.6046, 4.8496], country: "SS", name: "Juba" },
    { coordinate: [35.7497, -6.1670], country: "TZ", name: "Dodoma" },
    { coordinate: [1.2255, 6.1228], country: "TG", name: "Lome" },
    { coordinate: [10.1761, 36.8117], country: "TN", name: "Tunis" },
    { coordinate: [32.5714, 0.3133], country: "UG", name: "Kampala" },
    { coordinate: [28.2809, -15.4145], country: "ZM", name: "Lusaka" },
    { coordinate: [31.0496, -17.8227], country: "ZW", name: "Harare" },
    { coordinate: [45.2278, -12.7806], country: "YT", name: "Mamoudzou" },
    { coordinate: [55.4603, -20.8732], country: "RE", name: "Saint Denis" },
    { coordinate: [-5.7181, -15.9244], country: "SH", name: "Jamestown" },
    { coordinate: [-13.2033, 27.1536], country: "EH", name: "El Aaiun" },

    /* Oceania */
    { coordinate: [149.1286, -35.2820], country: "AU", name: "Canberra" },
    { coordinate: [178.4419, -18.1416], country: "FJ", name: "Suva" },
    { coordinate: [172.9784, 1.3282], country: "KI", name: "Bairiki" },
    { coordinate: [171.3667, 7.1167], country: "MH", name: "Majuro" },
    { coordinate: [158.1854, 6.9177], country: "FM", name: "Palikir" },
    { coordinate: [166.9196, -0.5434], country: "NR", name: "Yaren" },
    { coordinate: [174.7762, -41.2865], country: "NZ", name: "Wellington" },
    { coordinate: [134.6241, 7.5007], country: "PW", name: "Melekeok" },
    { coordinate: [147.1969, -9.4656], country: "PG", name: "Port Moresby" },
    { coordinate: [-171.7518, -13.8314], country: "WS", name: "Apia" },
    { coordinate: [159.9500, -9.4333], country: "SB", name: "Honiara" },
    { coordinate: [-175.2164, -21.1360], country: "TO", name: "Nuku'alofa" },
    { coordinate: [179.1983, -8.5210], country: "TV", name: "Vaiaku" },
    { coordinate: [168.3210, -17.7404], country: "VU", name: "Port Vila" },
    { coordinate: [-170.7009, -14.2793], country: "AS", name: "Pago Pago" },
    { coordinate: [105.6807, -10.4286], country: "CX", name: "Flying Fish Cove" },
    { coordinate: [96.8283, -12.1869], country: "CC", name: "West Island" },
    { coordinate: [-159.7658, -21.2039], country: "CK", name: "Avarua" },
    { coordinate: [-149.5696, -17.5350], country: "PF", name: "Papeete" },
    { coordinate: [144.7470, 13.4667], country: "GU", name: "Hagatna" },
    { coordinate: [166.4581, -22.2758], country: "NC", name: "Noumea" },
    { coordinate: [-169.9237, -19.0565], country: "NU", name: "Alofi" },
    { coordinate: [167.9666, -29.0545], country: "NF", name: "Kingston" },
    { coordinate: [145.7197, 15.2069], country: "MP", name: "Garapan" },
    { coordinate: [-130.1027, -25.0662], country: "PN", name: "Adamstown" },
    { coordinate: [-176.1430, -13.2784], country: "WF", name: "Mata-Utu" }
];
